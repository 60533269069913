import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { IconButton } from "@mui/material";
import { Container } from "@mui/system";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { Stepper, type StepState } from "../../../../Components/Stepper";
import { customersRoute } from "../../../../Navigation/core/utils";
import { consoleErrorWithSentry } from "../../../../utils";
import { useAzureFeatures } from "../db";
import { useAzureConnectApi } from "../hooks";
import ConnectResourcesStep from "./ConnectResourcesStep";
import { CreateServicePrincipalStep } from "./CreateServicePrincipalStep";
import { SelectFeaturesStep } from "./SelectFeaturesStep";
import type { AzureRole, RoleRequest } from "../types";

export const LinkAccountStepper = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const customerId = useCustomerId();
  const history = useHistory();
  const handleCancel = () => {
    history.push(`${customersRoute(customerId)}/settings/azure`);
  };

  const [tenantId, setTenantId] = useState("");
  const [stepsValid, setStepsValid] = useState([false, false, false]);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [roles, setRoles] = useState<AzureRole[]>([]);
  const [roleRequestData, setRoleRequestData] = useState<RoleRequest | undefined>(undefined);

  const { onOpen: openSnackbar, onClose: closeSnackbar } = useSnackbar();

  const getStepStatus = (step: number, currentStep: number): StepState => {
    if (step < currentStep) {
      return "complete";
    } else if (step === currentStep) {
      return stepsValid[currentStep] ? "complete" : "editing";
    }

    return "incomplete";
  };

  const [azureFeatures, loading, error] = useAzureFeatures();
  useEffect(() => {
    if (error) {
      openSnackbar({
        message: "Error reading applications",
        variant: "error",
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
    }
  }, [azureFeatures, error, openSnackbar, closeSnackbar]);

  const azureConnectApi = useAzureConnectApi();

  const handleSubmit = async () => {
    try {
      await azureConnectApi.addAzureRole(customerId, roleRequestData);
      history.push(`${customersRoute(customerId)}/settings/azure`);
    } catch (err) {
      openSnackbar({
        message: "Failed granting access",
        variant: "error",
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      consoleErrorWithSentry(err);
    }
  };

  return (
    <Container maxWidth="lg">
      <Stepper
        getCurrentStep={setCurrentStep}
        loading={false}
        submitButtonLabel="Done"
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        contentSx={{
          mt: 3,
          mb: 3,
        }}
        footerMaxWidth={860}
        maxWidth={1000}
        steps={[
          {
            children: (
              <SelectFeaturesStep
                loading={loading}
                setStepsValid={setStepsValid}
                currentStepIndex={0}
                stepValid={stepsValid}
                azureFeatures={azureFeatures}
                selectedFeatures={selectedFeatures}
                setSelectedFeatures={setSelectedFeatures}
              />
            ),
            label: "Select features",
            order: 0,
            required: true,
            state: getStepStatus(0, currentStep),
          },
          {
            children: (
              <CreateServicePrincipalStep
                tenantId={tenantId}
                setTenantId={setTenantId}
                setStepsValid={setStepsValid}
                currentStepIndex={1}
                stepsValid={stepsValid}
              />
            ),
            label: "Connect",
            order: 1,
            required: true,
            state: getStepStatus(1, currentStep),
          },
          {
            children: (
              <ConnectResourcesStep
                tenantID={tenantId}
                azureFeatures={azureFeatures}
                selectedFeaturesNames={selectedFeatures}
                roles={roles}
                setRoles={setRoles}
                roleRequestData={roleRequestData}
                setRoleRequestData={setRoleRequestData}
                setStepsValid={setStepsValid}
                currentStepIndex={2}
                stepsValid={stepsValid}
              />
            ),
            label: "Grant access",
            order: 2,
            required: true,
            state: getStepStatus(2, currentStep),
          },
        ]}
      />
    </Container>
  );
};
