import { isProduction } from "../../../constants";

export const AZURE_FEATURE_APP = isProduction
  ? {
      displayName: "Doit International",
      clientId: "15c1455a-e8eb-4479-ab5b-cea01e7aeede",
      tenantId: "5c0d2242-8740-49a1-9284-8dbb6365df6e",
    }
  : {
      displayName: "Doit International Dev",
      clientId: "8b383837-44d3-4760-a8ec-eea170b29f68",
      tenantId: "5c0d2242-8740-49a1-9284-8dbb6365df6e",
    };

export const rolePathPrefixes = {
  managementGroup: "/providers/Microsoft.Management/managementGroups",
  subscription: "/subscriptions",
  resource: "tbd", // todo add path or logic to obtain path
};

export const copyCommandVariables = {
  managementGroup: "$MANAGEMENT_GROUP_ID",
  subscription: "$SUBSCRIPTION_ID",
  resource: "$RESOURCE_ID",
};

export const copyCommandNames = {
  managementGroup: "Management Group ID",
  subscription: "Subscription ID",
  resource: "Resource ID",
};

export const OPEN_PORTAL_BUTTON = "Open Azure Cloud Shell";
