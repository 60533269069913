import React, { useEffect } from "react";

import {
  Box,
  Checkbox,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import type { AzureFeaturePermission } from "@services/cmp/models";

import { CollapsableTableRow } from "../../../../Components/CollapsableTableRow";
import { SafeHtml } from "../../../../Components/SafeHtml";
import { camelToTitleCase } from "../utils";

export type SelectFeaturesStepProps = {
  loading: boolean;
  setStepsValid: React.Dispatch<React.SetStateAction<boolean[]>>;
  stepValid: boolean[];
  currentStepIndex: number;
  azureFeatures: AzureFeaturePermission[] | undefined;
  selectedFeatures: string[];
  setSelectedFeatures: (features: string[]) => void;
};

export const SelectFeaturesStep = ({
  loading,
  selectedFeatures,
  setSelectedFeatures,
  azureFeatures,
  setStepsValid,
  currentStepIndex,
  stepValid,
}: SelectFeaturesStepProps) => {
  const handleSelectionChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (event.target.checked) {
      setSelectedFeatures([...selectedFeatures, name]);
    } else {
      setSelectedFeatures(selectedFeatures.filter((feature) => feature !== name));
    }
  };

  useEffect(() => {
    const stateValid = selectedFeatures?.length > 0;
    const newStatesValid = [...stepValid];
    newStatesValid[currentStepIndex] = stateValid;
    setStepsValid(newStatesValid);
  }, [stepValid, setStepsValid, currentStepIndex, selectedFeatures]);

  useEffect(() => {
    if (azureFeatures?.length === 1) {
      setSelectedFeatures([azureFeatures[0].name]);
    }
  }, [azureFeatures, setSelectedFeatures]);

  return loading || azureFeatures === undefined ? (
    <Box display="flex" justifyContent="center" alignItems="center" pt={4}>
      <CircularProgress />
    </Box>
  ) : (
    <Container maxWidth={false} sx={{ maxWidth: "688px" }} disableGutters key={"connect-azure-select-features"}>
      <>
        <Typography
          variant="h3"
          sx={{
            mb: 3,
            fontSize: 20,
          }}
        >
          Select which features you want to enable
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={15} />
              <TableCell>Available features</TableCell>
              <TableCell align="right" padding="checkbox">
                Include
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {azureFeatures.map((feature) => (
              <CollapsableTableRow
                key={feature.name}
                hiddenContent={
                  <Box m={1}>
                    <ul>
                      {feature.roles.map((role) => (
                        <li key={role.name}>
                          {camelToTitleCase(role.scope)}/{role.name}
                        </li>
                      ))}
                    </ul>
                  </Box>
                }
              >
                <TableCell component="th" scope="row">
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle1">
                      <SafeHtml html={feature.description} />
                    </Typography>
                  </Stack>
                </TableCell>

                <TableCell padding="none">
                  <Checkbox
                    color="primary"
                    disabled={selectedFeatures.length === 1}
                    checked={selectedFeatures.includes(feature.name)}
                    onChange={(event) => {
                      handleSelectionChange(event, feature.name);
                    }}
                  />
                </TableCell>
              </CollapsableTableRow>
            ))}
          </TableBody>
        </Table>
      </>
    </Container>
  );
};
