import { useMemo } from "react";

import { AppModel, type AzureFeaturePermission } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";

export const useAzureFeatures = (): [AzureFeaturePermission[] | undefined, boolean, boolean] => {
  const query = getCollection(AppModel).doc("cloud-connect");
  const [data, loading, error] = useDocumentDataOnce(query);

  const features = useMemo(
    (): AzureFeaturePermission[] => data?.azureFeaturePermissions as AzureFeaturePermission[],
    [data]
  );

  return [features, loading, error !== undefined];
};
