const TimezoneIOSList = {
  "Africa/Abidjan": "Abidjan, Africa (GMT)",
  "Africa/Accra": "Accra, Africa (GMT)",
  "Africa/Addis_Ababa": "Addis Ababa, Africa (GMT+3)",
  "Africa/Algiers": "Algiers, Africa (GMT+1)",
  "Africa/Asmara": "Asmara, Africa (GMT+3)",
  "Africa/Bamako": "Bamako, Africa (GMT)",
  "Africa/Bangui": "Bangui, Africa (GMT+1)",
  "Africa/Banjul": "Banjul, Africa (GMT)",
  "Africa/Bissau": "Bissau, Africa (GMT)",
  "Africa/Blantyre": "Blantyre, Africa (GMT+2)",
  "Africa/Brazzaville": "Brazzaville, Africa (GMT+1)",
  "Africa/Bujumbura": "Bujumbura, Africa (GMT+2)",
  "Africa/Cairo": "Cairo, Africa (GMT+2)",
  "Africa/Casablanca": "Casablanca, Africa (GMT+1)",
  "Africa/Ceuta": "Ceuta, Africa (GMT+1)",
  "Africa/Conakry": "Conakry, Africa (GMT)",
  "Africa/Dakar": "Dakar, Africa (GMT)",
  "Africa/Dar_es_Salaam": "Dar es Salaam, Africa (GMT+3)",
  "Africa/Djibouti": "Djibouti, Africa (GMT+3)",
  "Africa/Douala": "Douala, Africa (GMT+1)",
  "Africa/El_Aaiun": "El Aaiun, Africa (GMT+1)",
  "Africa/Freetown": "Freetown, Africa (GMT)",
  "Africa/Gaborone": "Gaborone, Africa (GMT+2)",
  "Africa/Harare": "Harare, Africa (GMT+2)",
  "Africa/Johannesburg": "Johannesburg, Africa (GMT+2)",
  "Africa/Juba": "Juba, Africa (GMT+2)",
  "Africa/Kampala": "Kampala, Africa (GMT+3)",
  "Africa/Khartoum": "Khartoum, Africa (GMT+2)",
  "Africa/Kigali": "Kigali, Africa (GMT+2)",
  "Africa/Kinshasa": "Kinshasa, Africa (GMT+1)",
  "Africa/Lagos": "Lagos, Africa (GMT+1)",
  "Africa/Libreville": "Libreville, Africa (GMT+1)",
  "Africa/Lome": "Lome, Africa (GMT)",
  "Africa/Luanda": "Luanda, Africa (GMT+1)",
  "Africa/Lubumbashi": "Lubumbashi, Africa (GMT+2)",
  "Africa/Lusaka": "Lusaka, Africa (GMT+2)",
  "Africa/Malabo": "Malabo, Africa (GMT+1)",
  "Africa/Maputo": "Maputo, Africa (GMT+2)",
  "Africa/Maseru": "Maseru, Africa (GMT+2)",
  "Africa/Mbabane": "Mbabane, Africa (GMT+2)",
  "Africa/Mogadishu": "Mogadishu, Africa (GMT+3)",
  "Africa/Monrovia": "Monrovia, Africa (GMT)",
  "Africa/Nairobi": "Nairobi, Africa (GMT+3)",
  "Africa/Ndjamena": "Ndjamena, Africa (GMT+1)",
  "Africa/Niamey": "Niamey, Africa (GMT+1)",
  "Africa/Nouakchott": "Nouakchott, Africa (GMT)",
  "Africa/Ouagadougou": "Ouagadougou, Africa (GMT)",
  "Africa/Porto-Novo": "Porto-Novo, Africa (GMT+1)",
  "Africa/Sao_Tome": "Sao Tome, Africa (GMT)",
  "Africa/Tripoli": "Tripoli, Africa (GMT+2)",
  "Africa/Tunis": "Tunis, Africa (GMT+1)",
  "Africa/Windhoek": "Windhoek, Africa (GMT+2)",
  "America/Adak": "Adak, America (GMT-10)",
  "America/Anchorage": "Anchorage, America (GMT-9)",
  "America/Anguilla": "Anguilla, America (GMT-4)",
  "America/Antigua": "Antigua, America (GMT-4)",
  "America/Araguaina": "Araguaina, America (GMT-3)",
  "America/Argentina/Buenos_Aires": "Buenos Aires, Argentina (GMT-3)",
  "America/Argentina/Catamarca": "Catamarca, Argentina (GMT-3)",
  "America/Argentina/Cordoba": "Cordoba, Argentina (GMT-3)",
  "America/Argentina/Jujuy": "Jujuy, Argentina (GMT-3)",
  "America/Argentina/La_Rioja": "La Rioja, Argentina (GMT-3)",
  "America/Argentina/Mendoza": "Mendoza, Argentina (GMT-3)",
  "America/Argentina/Rio_Gallegos": "Rio Gallegos, Argentina (GMT-3)",
  "America/Argentina/Salta": "Salta, Argentina (GMT-3)",
  "America/Argentina/San_Juan": "San Juan, Argentina (GMT-3)",
  "America/Argentina/San_Luis": "San Luis, Argentina (GMT-3)",
  "America/Argentina/Tucuman": "Tucuman, Argentina (GMT-3)",
  "America/Argentina/Ushuaia": "Ushuaia, Argentina (GMT-3)",
  "America/Aruba": "Aruba, America (GMT-4)",
  "America/Asuncion": "Asuncion, America (GMT-4)",
  "America/Atikokan": "Atikokan, America (GMT-5)",
  "America/Bahia": "Bahia, America (GMT-3)",
  "America/Bahia_Banderas": "Bahia Banderas, America (GMT-6)",
  "America/Barbados": "Barbados, America (GMT-4)",
  "America/Belem": "Belem, America (GMT-3)",
  "America/Belize": "Belize, America (GMT-6)",
  "America/Blanc-Sablon": "Blanc-Sablon, America (GMT-4)",
  "America/Boa_Vista": "Boa Vista, America (GMT-4)",
  "America/Bogota": "Bogota, America (GMT-5)",
  "America/Boise": "Boise, America (GMT-7)",
  "America/Cambridge_Bay": "Cambridge Bay, America (GMT-7)",
  "America/Campo_Grande": "Campo Grande, America (GMT-4)",
  "America/Cancun": "Cancun, America (GMT-5)",
  "America/Caracas": "Caracas, America (GMT-4)",
  "America/Cayenne": "Cayenne, America (GMT-3)",
  "America/Cayman": "Cayman, America (GMT-5)",
  "America/Chicago": "Chicago, America (GMT-6)",
  "America/Chihuahua": "Chihuahua, America (GMT-7)",
  "America/Costa_Rica": "Costa Rica, America (GMT-6)",
  "America/Creston": "Creston, America (GMT-7)",
  "America/Cuiaba": "Cuiaba, America (GMT-4)",
  "America/Curacao": "Curacao, America (GMT-4)",
  "America/Danmarkshavn": "Danmarkshavn, America (GMT-3)",
  "America/Dawson": "Dawson, America (GMT-8)",
  "America/Dawson_Creek": "Dawson Creek, America (GMT-7)",
  "America/Denver": "Denver, America (GMT-7)",
  "America/Detroit": "Detroit, America (GMT-5)",
  "America/Dominica": "Dominica, America (GMT-4)",
  "America/Edmonton": "Edmonton, America (GMT-7)",
  "America/Eirunepe": "Eirunepe, America (GMT-5)",
  "America/El_Salvador": "El Salvador, America (GMT-6)",
  "America/Fort_Nelson": "Fort Nelson, America (GMT-7)",
  "America/Fortaleza": "Fortaleza, America (GMT-3)",
  "America/Glace_Bay": "Glace Bay, America (GMT-4)",
  "America/Godthab": "Godthab, America (GMT-3)",
  "America/Goose_Bay": "Goose Bay, America (GMT-4)",
  "America/Grand_Turk": "Grand Turk, America (GMT-4)",
  "America/Grenada": "Grenada, America (GMT-4)",
  "America/Guadeloupe": "Guadeloupe, America (GMT-4)",
  "America/Guatemala": "Guatemala, America (GMT-6)",
  "America/Guayaquil": "Guayaquil, America (GMT-5)",
  "America/Guyana": "Guyana, America (GMT-4)",
  "America/Halifax": "Halifax, America (GMT-4)",
  "America/Havana": "Havana, America (GMT-5)",
  "America/Hermosillo": "Hermosillo, America (GMT-7)",
  "America/Indiana/Indianapolis": "Indianapolis, America (GMT-5)",
  "America/Indiana/Knox": "Knox, America (GMT-6)",
  "America/Indiana/Marengo": "Marengo, America (GMT-5)",
  "America/Indiana/Petersburg": "Petersburg, America (GMT-5)",
  "America/Indiana/Tell_City": "Tell City, America (GMT-6)",
  "America/Indiana/Vevay": "Vevay, America (GMT-5)",
  "America/Indiana/Vincennes": "Vincennes, America (GMT-5)",
  "America/Indiana/Winamac": "Winamac, America (GMT-5)",
  "America/Inuvik": "Inuvik, America (GMT-7)",
  "America/Iqaluit": "Iqaluit, America (GMT-5)",
  "America/Jamaica": "Jamaica, America (GMT-5)",
  "America/Juneau": "Juneau, America (GMT-9)",
  "America/Kentucky/Louisville": "Louisville, America (GMT-5)",
  "America/Kentucky/Monticello": "Monticello, America (GMT-5)",
  "America/Kralendijk": "Kralendijk, America (GMT-4)",
  "America/La_Paz": "La Paz, America (GMT-4)",
  "America/Lima": "Lima, America (GMT-5)",
  "America/Los_Angeles": "Los Angeles, America (GMT-8)",
  "America/Lower_Princes": "Lower Princes, America (GMT-4)",
  "America/Maceio": "Maceio, America (GMT-3)",
  "America/Managua": "Managua, America (GMT-6)",
  "America/Manaus": "Manaus, America (GMT-4)",
  "America/Marigot": "Marigot, America (GMT-4)",
  "America/Martinique": "Martinique, America (GMT-4)",
  "America/Matamoros": "Matamoros, America (GMT-6)",
  "America/Mazatlan": "Mazatlan, America (GMT-7)",
  "America/Menominee": "Menominee, America (GMT-6)",
  "America/Merida": "Merida, America (GMT-6)",
  "America/Metlakatla": "Metlakatla, America (GMT-8)",
  "America/Mexico_City": "Mexico City, America (GMT-6)",
  "America/Miquelon": "Miquelon, America (GMT-3)",
  "America/Moncton": "Moncton, America (GMT-4)",
  "America/Monterrey": "Monterrey, America (GMT-6)",
  "America/Montevideo": "Montevideo, America (GMT-3)",
  "America/Montreal": "Montreal, America (GMT-5)",
  "America/Montserrat": "Montserrat, America (GMT-4)",
  "America/Nassau": "Nassau, America (GMT-5)",
  "America/New_York": "New York, America (GMT-5)",
  "America/Nipigon": "Nipigon, America (GMT-5)",
  "America/Nome": "Nome, America (GMT-9)",
  "America/Noronha": "Noronha, America (GMT-2)",
  "America/North_Dakota/Beulah": "Beulah, America (GMT-6)",
  "America/North_Dakota/Center": "Center, America (GMT-6)",
  "America/North_Dakota/New_Salem": "New Salem, America (GMT-6)",
  "America/Ojinaga": "Ojinaga, America (GMT-7)",
  "America/Panama": "Panama, America (GMT-5)",
  "America/Pangnirtung": "Pangnirtung, America (GMT-5)",
  "America/Paramaribo": "Paramaribo, America (GMT-3)",
  "America/Phoenix": "Phoenix, America (GMT-7)",
  "America/Port-au-Prince": "Port-au-Prince, America (GMT-5)",
  "America/Port_of_Spain": "Port of Spain, America (GMT-4)",
  "America/Porto_Velho": "Porto Velho, America (GMT-4)",
  "America/Puerto_Rico": "Puerto Rico, America (GMT-4)",
  "America/Rainy_River": "Rainy River, America (GMT-6)",
  "America/Rankin_Inlet": "Rankin Inlet, America (GMT-6)",
  "America/Recife": "Recife, America (GMT-3)",
  "America/Regina": "Regina, America (GMT-6)",
  "America/Resolute": "Resolute, America (GMT-5)",
  "America/Rio_Branco": "Rio Branco, America (GMT-5)",
  "America/Santa_Isabel": "Santa Isabel, America (GMT-4)",
  "America/Santarem": "Santarem, America (GMT-3)",
  "America/Santiago": "Santiago, America (GMT-3)",
  "America/Santo_Domingo": "Santo Domingo, America (GMT-4)",
  "America/Sao_Paulo": "Sao Paulo, America (GMT-3)",
  "America/Scoresbysund": "Scoresbysund, America (GMT-3)",
  "America/Shiprock": "Shiprock, America (GMT-7)",
  "America/Sitka": "Sitka, America (GMT-9)",
  "America/St_Barthelemy": "St Barthelemy, America (GMT-4)",
  "America/St_Johns": "St Johns, America (GMT-3:30)",
  "America/St_Kitts": "St Kitts, America (GMT-4)",
  "America/St_Lucia": "St Lucia, America (GMT-4)",
  "America/St_Thomas": "St Thomas, America (GMT-4)",
  "America/St_Vincent": "St Vincent, America (GMT-4)",
  "America/Swift_Current": "Swift Current, America (GMT-6)",
  "America/Tegucigalpa": "Tegucigalpa, America (GMT-6)",
  "America/Thule": "Thule, America (GMT-4)",
  "America/Thunder_Bay": "Thunder Bay, America (GMT-5)",
  "America/Tijuana": "Tijuana, America (GMT-8)",
  "America/Toronto": "Toronto, America (GMT-5)",
  "America/Tortola": "Tortola, America (GMT-4)",
  "America/Vancouver": "Vancouver, America (GMT-8)",
  "America/Whitehorse": "Whitehorse, America (GMT-8)",
  "America/Winnipeg": "Winnipeg, America (GMT-6)",
  "America/Yakutat": "Yakutat, America (GMT-9)",
  "America/Yellowknife": "Yellowknife, America (GMT-7)",
  "Antarctica/Casey": "Casey, Antarctica (GMT+8)",
  "Antarctica/Davis": "Davis, Antarctica (GMT+7)",
  "Antarctica/DumontDUrville": "DumontDUrville, Antarctica (GMT+10)",
  "Antarctica/Macquarie": "Macquarie, Antarctica (GMT+11)",
  "Antarctica/Mawson": "Mawson, Antarctica (GMT+5)",
  "Antarctica/McMurdo": "McMurdo, Antarctica (GMT+12)",
  "Antarctica/Palmer": "Palmer, Antarctica (GMT-3)",
  "Antarctica/Rothera": "Rothera, Antarctica (GMT-3)",
  "Antarctica/South_Pole": "South Pole, Antarctica (GMT+12)",
  "Antarctica/Syowa": "Syowa, Antarctica (GMT+3)",
  "Antarctica/Troll": "Troll, Antarctica (GMT+2)",
  "Antarctica/Vostok": "Vostok, Antarctica (GMT+6)",
  "Arctic/Longyearbyen": "Longyearbyen, Arctic (GMT+1)",
  "Asia/Aden": "Aden, Asia (GMT+3)",
  "Asia/Almaty": "Almaty, Asia (GMT+6)",
  "Asia/Amman": "Amman, Asia (GMT+2)",
  "Asia/Anadyr": "Anadyr, Asia (GMT+12)",
  "Asia/Aqtau": "Aqtau, Asia (GMT+5)",
  "Asia/Aqtobe": "Aqtobe, Asia (GMT+5)",
  "Asia/Ashgabat": "Ashgabat, Asia (GMT+5)",
  "Asia/Baghdad": "Baghdad, Asia (GMT+3)",
  "Asia/Bahrain": "Bahrain, Asia (GMT+3)",
  "Asia/Baku": "Baku, Asia (GMT+4)",
  "Asia/Bangkok": "Bangkok, Asia (GMT+7)",
  "Asia/Barnaul": "Barnaul, Asia (GMT+7)",
  "Asia/Beirut": "Beirut, Asia (GMT+2)",
  "Asia/Bishkek": "Bishkek, Asia (GMT+6)",
  "Asia/Brunei": "Brunei, Asia (GMT+8)",
  "Asia/Chita": "Chita, Asia (GMT+9)",
  "Asia/Choibalsan": "Choibalsan, Asia (GMT+8)",
  "Asia/Chongqing": "Chongqing, Asia (GMT+8)",
  "Asia/Colombo": "Colombo, Asia (GMT+5:30)",
  "Asia/Damascus": "Damascus, Asia (GMT+2)",
  "Asia/Dhaka": "Dhaka, Asia (GMT+6)",
  "Asia/Dili": "Dili, Asia (GMT+9)",
  "Asia/Dubai": "Dubai, Asia (GMT+4)",
  "Asia/Dushanbe": "Dushanbe, Asia (GMT+5)",
  "Asia/Gaza": "Gaza, Asia (GMT+2)",
  "Asia/Harbin": "Harbin, Asia (GMT+8)",
  "Asia/Hebron": "Hebron, Asia (GMT+2)",
  "Asia/Ho_Chi_Minh": "Ho Chi Minh, Asia (GMT+7)",
  "Asia/Hong_Kong": "Hong Kong, Asia (GMT+8)",
  "Asia/Hovd": "Hovd, Asia (GMT+7)",
  "Asia/Irkutsk": "Irkutsk, Asia (GMT+8)",
  "Asia/Jakarta": "Jakarta, Asia (GMT+7)",
  "Asia/Jayapura": "Jayapura, Asia (GMT+9)",
  "Asia/Jerusalem": "Jerusalem, Asia (GMT+2)",
  "Asia/Kabul": "Kabul, Asia (GMT+4:30)",
  "Asia/Kamchatka": "Kamchatka, Asia (GMT+12)",
  "Asia/Karachi": "Karachi, Asia (GMT+5)",
  "Asia/Kashgar": "Kashgar, Asia (GMT+6)",
  "Asia/Kathmandu": "Kathmandu, Asia (GMT+5:45)",
  "Asia/Katmandu": "Katmandu, Asia (GMT+5:45)",
  "Asia/Khandyga": "Khandyga, Asia (GMT+9)",
  "Asia/Kolkata": "Kolkata, Asia (GMT+5:30)",
  "Asia/Krasnoyarsk": "Krasnoyarsk, Asia (GMT+7)",
  "Asia/Kuala_Lumpur": "Kuala Lumpur, Asia (GMT+8)",
  "Asia/Kuching": "Kuching, Asia (GMT+8)",
  "Asia/Kuwait": "Kuwait, Asia (GMT+3)",
  "Asia/Macau": "Macau, Asia (GMT+8)",
  "Asia/Magadan": "Magadan, Asia (GMT+10)",
  "Asia/Makassar": "Makassar, Asia (GMT+8)",
  "Asia/Manila": "Manila, Asia (GMT+8)",
  "Asia/Muscat": "Muscat, Asia (GMT+4)",
  "Asia/Nicosia": "Nicosia, Asia (GMT+2)",
  "Asia/Novokuznetsk": "Novokuznetsk, Asia (GMT+7)",
  "Asia/Novosibirsk": "Novosibirsk, Asia (GMT+7)",
  "Asia/Omsk": "Omsk, Asia (GMT+6)",
  "Asia/Oral": "Oral, Asia (GMT+5)",
  "Asia/Phnom_Penh": "Phnom Penh, Asia (GMT+7)",
  "Asia/Pontianak": "Pontianak, Asia (GMT+7)",
  "Asia/Pyongyang": "Pyongyang, Asia (GMT+8)",
  "Asia/Qatar": "Qatar, Asia (GMT+3)",
  "Asia/Qyzylorda": "Qyzylorda, Asia (GMT+6)",
  "Asia/Rangoon": "Rangoon, Asia (GMT+6:30)",
  "Asia/Riyadh": "Riyadh, Asia (GMT+3)",
  "Asia/Sakhalin": "Sakhalin, Asia (GMT+11)",
  "Asia/Samarkand": "Samarkand, Asia (GMT+5)",
  "Asia/Seoul": "Seoul, Asia (GMT+9)",
  "Asia/Shanghai": "Shanghai, Asia (GMT+8)",
  "Asia/Singapore": "Singapore, Asia (GMT+8)",
  "Asia/Srednekolymsk": "Srednekolymsk, Asia (GMT+11)",
  "Asia/Taipei": "Taipei, Asia (GMT+8)",
  "Asia/Tashkent": "Tashkent, Asia (GMT+5)",
  "Asia/Tbilisi": "Tbilisi, Asia (GMT+4)",
  "Asia/Tehran": "Tehran, Asia (GMT+3:30)",
  "Asia/Thimphu": "Thimphu, Asia (GMT+6)",
  "Asia/Tokyo": "Tokyo, Asia (GMT+9)",
  "Asia/Tomsk": "Tomsk, Asia (GMT+7)",
  "Asia/Ulaanbaatar": "Ulaanbaatar, Asia (GMT+8)",
  "Asia/Urumqi": "Urumqi, Asia (GMT+6)",
  "Asia/Ust-Nera": "Ust-Nera, Asia (GMT+10)",
  "Asia/Vientiane": "Vientiane, Asia (GMT+7)",
  "Asia/Vladivostok": "Vladivostok, Asia (GMT+10)",
  "Asia/Yakutsk": "Yakutsk, Asia (GMT+9)",
  "Asia/Yekaterinburg": "Yekaterinburg, Asia (GMT+5)",
  "Asia/Yerevan": "Yerevan, Asia (GMT+4)",
  "Atlantic/Azores": "Azores, Atlantic (GMT-1)",
  "Atlantic/Bermuda": "Bermuda, Atlantic (GMT-4)",
  "Atlantic/Canary": "Canary, Atlantic (GMT)",
  "Atlantic/Cape_Verde": "Cape Verde, Atlantic (GMT-1)",
  "Atlantic/Faroe": "Faroe, Atlantic (GMT)",
  "Atlantic/Madeira": "Madeira, Atlantic (GMT)",
  "Atlantic/Reykjavik": "Reykjavik, Atlantic (GMT)",
  "Atlantic/South_Georgia": "South Georgia, Atlantic (GMT-2)",
  "Atlantic/St_Helena": "St Helena, Atlantic (GMT)",
  "Atlantic/Stanley": "Stanley, Atlantic (GMT-3)",
  "Australia/Adelaide": "Adelaide, Australia (GMT+10:30)",
  "Australia/Brisbane": "Brisbane, Australia (GMT+10)",
  "Australia/Broken_Hill": "Broken Hill, Australia (GMT+10:30)",
  "Australia/Currie": "Currie, Australia (GMT+10)",
  "Australia/Darwin": "Darwin, Australia (GMT+9:30)",
  "Australia/Eucla": "Eucla, Australia (GMT+8:45)",
  "Australia/Hobart": "Hobart, Australia (GMT+11)",
  "Australia/Lindeman": "Lindeman, Australia (GMT+10)",
  "Australia/Lord_Howe": "Lord Howe, Australia (GMT+11)",
  "Australia/Melbourne": "Melbourne, Australia (GMT+11)",
  "Australia/Perth": "Perth, Australia (GMT+8)",
  "Australia/Sydney": "Sydney, Australia (GMT+11)",
  "Europe/Amsterdam": "Amsterdam, Europe (GMT+1)",
  "Europe/Andorra": "Andorra, Europe (GMT+1)",
  "Europe/Astrakhan": "Astrakhan, Europe (GMT+4)",
  "Europe/Athens": "Athens, Europe (GMT+2)",
  "Europe/Belgrade": "Belgrade, Europe (GMT+1)",
  "Europe/Berlin": "Berlin, Europe (GMT+1)",
  "Europe/Bratislava": "Bratislava, Europe (GMT+1)",
  "Europe/Brussels": "Brussels, Europe (GMT+1)",
  "Europe/Bucharest": "Bucharest, Europe (GMT+2)",
  "Europe/Budapest": "Budapest, Europe (GMT+1)",
  "Europe/Busingen": "Busingen, Europe (GMT+1)",
  "Europe/Chisinau": "Chisinau, Europe (GMT+2)",
  "Europe/Copenhagen": "Copenhagen, Europe (GMT+1)",
  "Europe/Dublin": "Dublin, Europe (GMT)",
  "Europe/Gibraltar": "Gibraltar, Europe (GMT+1)",
  "Europe/Guernsey": "Guernsey, Europe (GMT)",
  "Europe/Helsinki": "Helsinki, Europe (GMT+2)",
  "Europe/Isle_of_Man": "Isle of Man, Europe (GMT)",
  "Europe/Istanbul": "Istanbul, Europe (GMT+3)",
  "Europe/Jersey": "Jersey, Europe (GMT)",
  "Europe/Kaliningrad": "Kaliningrad, Europe (GMT+2)",
  "Europe/Kiev": "Kiev, Europe (GMT+2)",
  "Europe/Kirov": "Kirov, Europe (GMT+3)",
  "Europe/Lisbon": "Lisbon, Europe (GMT)",
  "Europe/Ljubljana": "Ljubljana, Europe (GMT+1)",
  "Europe/London": "London, Europe (GMT)",
  "Europe/Luxembourg": "Luxembourg, Europe (GMT+1)",
  "Europe/Madrid": "Madrid, Europe (GMT+1)",
  "Europe/Malta": "Malta, Europe (GMT+1)",
  "Europe/Mariehamn": "Mariehamn, Europe (GMT+2)",
  "Europe/Minsk": "Minsk, Europe (GMT+3)",
  "Europe/Monaco": "Monaco, Europe (GMT+1)",
  "Europe/Moscow": "Moscow, Europe (GMT+3)",
  "Europe/Oslo": "Oslo, Europe (GMT+1)",
  "Europe/Paris": "Paris, Europe (GMT+1)",
  "Europe/Podgorica": "Podgorica, Europe (GMT+1)",
  "Europe/Prague": "Prague, Europe (GMT+1)",
  "Europe/Riga": "Riga, Europe (GMT+2)",
  "Europe/Rome": "Rome, Europe (GMT+1)",
  "Europe/Samara": "Samara, Europe (GMT+4)",
  "Europe/San_Marino": "San Marino, Europe (GMT+1)",
  "Europe/Sarajevo": "Sarajevo, Europe (GMT+1)",
  "Europe/Simferopol": "Simferopol, Europe (GMT+3)",
  "Europe/Skopje": "Skopje, Europe (GMT+1)",
  "Europe/Sofia": "Sofia, Europe (GMT+2)",
  "Europe/Stockholm": "Stockholm, Europe (GMT+1)",
  "Europe/Tallinn": "Tallinn, Europe (GMT+2)",
  "Europe/Tirane": "Tirane, Europe (GMT+1)",
  "Europe/Ulyanovsk": "Ulyanovsk, Europe (GMT+4)",
  "Europe/Uzhgorod": "Uzhgorod, Europe (GMT+2)",
  "Europe/Vaduz": "Vaduz, Europe (GMT+1)",
  "Europe/Vatican": "Vatican, Europe (GMT+1)",
  "Europe/Vienna": "Vienna, Europe (GMT+1)",
  "Europe/Vilnius": "Vilnius, Europe (GMT+2)",
  "Europe/Volgograd": "Volgograd, Europe (GMT+4)",
  "Europe/Warsaw": "Warsaw, Europe (GMT+1)",
  "Europe/Zagreb": "Zagreb, Europe (GMT+1)",
  "Europe/Zaporozhye": "Zaporozhye, Europe (GMT+2)",
  "Europe/Zurich": "Zurich, Europe (GMT+1)",
  GMT: "Greenwich Mean Time (GMT)",
  UTC: "Greenwich Mean Time (GMT)",
  "Indian/Antananarivo": "Antananarivo, Indian (GMT+3)",
  "Indian/Chagos": "Chagos, Indian (GMT+6)",
  "Indian/Christmas": "Christmas, Indian (GMT+7)",
  "Indian/Cocos": "Cocos, Indian (GMT+6:30)",
  "Indian/Comoro": "Comoro, Indian (GMT+3)",
  "Indian/Kerguelen": "Kerguelen, Indian (GMT+5)",
  "Indian/Mahe": "Mahe, Indian (GMT+4)",
  "Indian/Maldives": "Maldives, Indian (GMT+5)",
  "Indian/Mauritius": "Mauritius, Indian (GMT+4)",
  "Indian/Mayotte": "Mayotte, Indian (GMT+3)",
  "Indian/Reunion": "Reunion, Indian (GMT+4)",
  "Pacific/Apia": "Apia, Pacific (GMT+13)",
  "Pacific/Auckland": "Auckland, Pacific (GMT+13)",
  "Pacific/Bougainville": "Bougainville, Pacific (GMT+11)",
  "Pacific/Chatham": "Chatham, Pacific (GMT+12:45)",
  "Pacific/Chuuk": "Chuuk, Pacific (GMT+10)",
  "Pacific/Easter": "Easter, Pacific (GMT-6)",
  "Pacific/Efate": "Efate, Pacific (GMT+11)",
  "Pacific/Enderbury": "Enderbury, Pacific (GMT+12)",
  "Pacific/Fakaofo": "Fakaofo, Pacific (GMT+13)",
  "Pacific/Fiji": "Fiji, Pacific (GMT+12)",
  "Pacific/Funafuti": "Funafuti, Pacific (GMT+12)",
  "Pacific/Galapagos": "Galapagos, Pacific (GMT-6)",
  "Pacific/Gambier": "Gambier, Pacific (GMT-9)",
  "Pacific/Guadalcanal": "Guadalcanal, Pacific (GMT+11)",
  "Pacific/Guam": "Guam, Pacific (GMT+10)",
  "Pacific/Honolulu": "Honolulu, Pacific (GMT-10)",
  "Pacific/Johnston": "Johnston, Pacific (GMT-10)",
  "Pacific/Kiritimati": "Kiritimati, Pacific (GMT+14)",
  "Pacific/Kosrae": "Kosrae, Pacific (GMT+11)",
  "Pacific/Kwajalein": "Kwajalein, Pacific (GMT+12)",
  "Pacific/Majuro": "Majuro, Pacific (GMT+12)",
  "Pacific/Marquesas": "Marquesas, Pacific (GMT-9)",
  "Pacific/Midway": "Midway, Pacific (GMT-11)",
  "Pacific/Nauru": "Nauru, Pacific (GMT+12)",
  "Pacific/Niue": "Niue, Pacific (GMT-11)",
  "Pacific/Norfolk": "Norfolk, Pacific (GMT+11)",
  "Pacific/Noumea": "Noumea, Pacific (GMT+11)",
  "Pacific/Pago_Pago": "Pago Pago, Pacific (GMT-11)",
  "Pacific/Palau": "Palau, Pacific (GMT+9)",
  "Pacific/Pitcairn": "Pitcairn, Pacific (GMT-8)",
  "Pacific/Pohnpei": "Pohnpei, Pacific (GMT+11)",
  "Pacific/Ponape": "Ponape, Pacific (GMT+11)",
  "Pacific/Port_Moresby": "Port Moresby, Pacific (GMT+10)",
  "Pacific/Rarotonga": "Rarotonga, Pacific (GMT-10)",
  "Pacific/Saipan": "Saipan, Pacific (GMT+10)",
  "Pacific/Tahiti": "Tahiti, Pacific (GMT-10)",
  "Pacific/Tarawa": "Tarawa, Pacific (GMT+12)",
  "Pacific/Tongatapu": "Tongatapu, Pacific (GMT+13)",
  "Pacific/Truk": "Truk, Pacific (GMT+10)",
  "Pacific/Wake": "Wake, Pacific (GMT+12)",
  "Pacific/Wallis": "Wallis, Pacific (GMT+12)",
} as const;

export default TimezoneIOSList;
