import React, { useEffect } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";

import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { AZURE_FEATURE_APP, OPEN_PORTAL_BUTTON } from "../consts";

type Props = {
  setStepsValid: React.Dispatch<React.SetStateAction<boolean[]>>;
  stepsValid: boolean[];
  currentStepIndex: number;
  tenantId: string;
  setTenantId: React.Dispatch<React.SetStateAction<string>>;
};

export const CreateServicePrincipalStep = ({
  tenantId,
  setTenantId,
  setStepsValid,
  currentStepIndex,
  stepsValid,
}: Props) => {
  const createServicePrincipalCmd = `az ad sp create --id ${AZURE_FEATURE_APP.clientId}`;

  useEffect(() => {
    const stateValid = tenantId !== "";
    const newStatesValid = [...stepsValid];
    newStatesValid[currentStepIndex] = stateValid;
    setStepsValid(newStatesValid);
  }, [stepsValid, setStepsValid, currentStepIndex, tenantId]);

  const handleTenantIdChanged = (value) => {
    setTenantId(value);
  };

  return (
    <>
      <Container
        maxWidth={false}
        sx={{ maxWidth: "688px" }}
        disableGutters
        key={"connect-azure-service-principal-container"}
      >
        <>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 4,
            }}
            noValidate
            autoComplete="off"
          >
            <Typography
              variant="h3"
              sx={{
                mb: 3,
                fontSize: 20,
              }}
            >
              Connect Microsoft Azure tenant to DoiT
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.87 }}>
              1. Enter tenant ID
            </Typography>
            <TextField
              required
              label="Tenant ID"
              variant="outlined"
              value={tenantId}
              onChange={(e) => {
                handleTenantIdChanged(e.target.value);
              }}
            />

            <>
              <Typography variant="body2" sx={{ opacity: 0.87 }}>
                2. Run the following commands on the Azure portal
              </Typography>

              <Button
                startIcon={<OpenInNewIcon />}
                variant="outlined"
                sx={{ alignSelf: "flex-start" }}
                href="https://portal.azure.com/#cloudshell"
                target="_blank"
              >
                {OPEN_PORTAL_BUTTON}
              </Button>

              <Typography variant="body2" sx={{ opacity: 0.6 }}>
                Create DoiT International Service Principal (Enterprise Application)
              </Typography>
              <CopyCodeBlock base={createServicePrincipalCmd} />
            </>
          </Box>
        </>
      </Container>
    </>
  );
};
